.Wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;

  .Item {

    .Count {
      max-width: 75px;
    }

    .Prepend {
      border-right: 0;
      border-radius: .25rem 0 0 .25rem;
    }

    &.SortContainer {
      margin-left: auto;
      margin-right: 1rem;
    }

    .SortBy {
      max-width: max-content;
    }

    input:focus {
      box-shadow: none;
    }
  }
}
.Icon {
  margin-left: .5rem;

  &.CheckIcon {
    color: var(--success);
  }

  &.CrossIcon {
    color: var(--danger);
  }
}

.CheckInsList {
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: auto;

  p {
    margin: 0;
    color: red;
  }
}
.Navbar {
  padding: 0.75rem;
  border-bottom: 1px solid #dedede;
}

.LogoutBtn {
  font-size: 1.2rem;

  &:hover {
    text-decoration: none;
  }
}